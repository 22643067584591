import {
  FileUploadsQuery,
  FileUploadsQueryVariables,
} from '@/generated/operations';
import { FileUploads } from '@/graphql/operations';
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  DataProxy,
  Resolvers,
} from '@apollo/client/core';

const httpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP,
});

const resolvers: Resolvers = {
  Mutation: {
    createFileUpload: (_, { input }, { cache }: { cache: InMemoryCache }) => {
      const fileUploadsQuery = safelyReadQuery<
        FileUploadsQuery,
        FileUploadsQueryVariables
      >({
        query: FileUploads,
        variables: { filter: { metadata: input.metadata } },
      });

      if (fileUploadsQuery) {
        cache.writeQuery<FileUploadsQuery, FileUploadsQueryVariables>({
          query: FileUploads,
          data: {
            ...fileUploadsQuery,
            fileUploads: [
              ...fileUploadsQuery.fileUploads,
              { __typename: 'FileUpload', ...input },
            ],
          },
          variables: { filter: { metadata: input.metadata } },
        });
      } else {
        cache.writeQuery<FileUploadsQuery, FileUploadsQueryVariables>({
          query: FileUploads,
          data: {
            __typename: 'Query',
            fileUploads: [{ __typename: 'FileUpload', ...input }],
          },
          variables: { filter: { metadata: input.metadata } },
        });
      }

      return true;
    },
    updateFileUpload: (_, { input }, { cache }: { cache: InMemoryCache }) => {
      const fileUploadsQuery = safelyReadQuery<
        FileUploadsQuery,
        FileUploadsQueryVariables
      >({
        query: FileUploads,
        variables: { filter: { metadata: input.metadata } },
      });

      if (fileUploadsQuery) {
        cache.writeQuery<FileUploadsQuery, FileUploadsQueryVariables>({
          query: FileUploads,
          data: {
            ...fileUploadsQuery,
            fileUploads: fileUploadsQuery.fileUploads.map((f) =>
              f.uid === input.uid ? { __typename: 'FileUpload', ...input } : f,
            ),
          },
          variables: {
            filter: { metadata: input.metadata },
          },
        });
      }
    },
    removeFileUpload: (_, { input }, { cache }: { cache: InMemoryCache }) => {
      const fileUploadsQuery = safelyReadQuery<
        FileUploadsQuery,
        FileUploadsQueryVariables
      >({
        query: FileUploads,
        variables: { filter: { metadata: input.metadata } },
      });

      if (fileUploadsQuery) {
        cache.writeQuery<FileUploadsQuery, FileUploadsQueryVariables>({
          query: FileUploads,
          data: {
            ...fileUploadsQuery,
            fileUploads: fileUploadsQuery.fileUploads.filter(
              (f) => f.uid !== input.uid,
            ),
          },
          variables: { filter: { metadata: input.metadata } },
        });
      }

      return true;
    },
  },
};

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        formQueries: {
          merge: true,
        },
      },
    },
  },
});

const apolloClient = new ApolloClient({
  cache,
  credentials: 'include',
  link: httpLink,
  resolvers,
});

export function safelyReadQuery<T, U>({
  query,
  variables,
}: DataProxy.Query<U, T>) {
  try {
    return apolloClient.readQuery<T, U>({ query, variables });
  } catch {
    return null;
  }
}

export default apolloClient;
