import Home from '@/views/Home.vue';
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

import { formGuard } from './guards';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/404',
    name: 'FormUnavailable',
    component: () =>
      import(
        /* webpackChunkName: "form-unavailable" */ '@/views/FormUnavailable.vue'
      ),
  },
  {
    path: '/f/:formId',
    name: 'Form',
    beforeEnter: formGuard,
    component: () => import(/* webpackChunkName: "form" */ '@/views/Form.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
