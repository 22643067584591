









import { defineComponent, onMounted } from '@vue/composition-api';

export default defineComponent({
  name: 'App',
  metaInfo() {
    return {
      titleTemplate: (titleChunk) =>
        titleChunk ? `${titleChunk} \u00B7 Blue Forms` : 'Blue Forms',
    };
  },
  setup(props, { root }) {
    onMounted(() =>
      root.$router.onReady(() =>
        document.getElementById('app')?.classList.add('ready'),
      ),
    );
  },
});
