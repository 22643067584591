import gql from 'graphql-tag';

export const SubmitForm = gql`
  mutation SubmitForm($input: SubmitFormInput!) {
    submitForm(input: $input)
  }
`;

export const Ping = gql`
  query Ping {
    ping
  }
`;

export const FileUploads = gql`
  query FileUploads($filter: FileUploadFilterInput) {
    fileUploads(filter: $filter) @client {
      uid
      progress
      completed
      name
      type
      extension
      size
      xhr
    }
  }
`;

export const CreateFileUpload = gql`
  mutation CreateFileUpload($input: CreateFileUploadInput!) {
    createFileUpload(input: $input) @client
  }
`;

export const RemoveFileUpload = gql`
  mutation RemoveFileUpload($input: RemoveFileUploadInput!) {
    removeFileUpload(input: $input) @client
  }
`;

export const UpdateFileUpload = gql`
  mutation UpdateFileUpload($input: UpdateFileUploadInput!) {
    updateFileUpload(input: $input) @client
  }
`;
