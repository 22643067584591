import './registerServiceWorker';
import './styles/main.scss';

import { DefaultApolloClient } from '@vue/apollo-composable';
import VueCompositionAPI, { provide } from '@vue/composition-api';
import PortalVue from 'portal-vue';
import Vue from 'vue';
import VModal from 'vue-js-modal';
import VueMeta from 'vue-meta';

import App from './App.vue';
import apolloClient from './plugins/apollo';
import vuetify from './plugins/vuetify';
import router from './router';

Vue.config.productionTip = false;

Vue.use(VueCompositionAPI);
Vue.use(VueMeta);
Vue.use(VModal, { componentName: 'v-modal' });
Vue.use(PortalVue);

new Vue({
  router,
  vuetify,
  setup() {
    provide(DefaultApolloClient, apolloClient);
    return {};
  },
  render: (h) => h(App),
}).$mount('#app');
