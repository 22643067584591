import { NavigationGuard } from 'vue-router';

export const formGuard: NavigationGuard = async (to, from, next) => {
  try {
    const res = await fetch(
      `${process.env.VUE_APP_BLUE_API}/forms/${to.params.formId}`,
    );
    return res.status === 200 ? next() : next({ name: 'FormUnavailable' }); // TODO: make a 404 page
  } catch (err) {
    return next({ name: 'FormUnavailable' });
  }
};
